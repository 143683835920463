import { ApiReferenceReact } from "@scalar/api-reference-react";
import tg_spec from "./openapi.json";
import "@scalar/api-reference-react/style.css";

import React, { useEffect, useState } from "react";

function App() {
  const [spec, setSpec] = useState({ ...tg_spec });

  useEffect(() => {

    const findNReplace = () => {
      try {
        const elements = document.querySelectorAll(".endpoint-description");
        elements.forEach((element) => {
          if (element.innerHTML.includes("hostname_replace_me")) {
            clearInterval(intervalId);
            element.innerHTML = element.innerHTML.replace(
              /hostname_replace_me/g,
              "https://community.teselagen.com"
            );
          }
        });
      } catch (error) {
        console.error(`error:`, error);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(() => {
      findNReplace();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <ApiReferenceReact
      configuration={{
        hideModels: true,
        hideDownloadButton: true,
        servers: [
          {
            url: window.location.origin + "/tg-api",
          },
        ],
        metaData: {
          title: "TeselaGen API",
        },
        tagsSorter: "alpha",
        spec: {
          content: spec,
        },
      }}
    />
  );
}

export default App;
